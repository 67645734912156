<div class="home-page">
	<div class="carousel-wrapper">
		<ngb-carousel #carousel [pauseOnHover]="false" [pauseOnFocus]="false" [showNavigationArrows]="false" [interval]="10000" (slide)="onCarouselSlide($event)">
			<ng-template ngbSlide *ngFor="let item of carouselImages">
				<div [ngStyle]="{background: 'url(/src/images/' + item.img + ')'}" class="picsum-img-wrapper carousel-image"></div>
				<div class="carousel-caption">
					<span class="slide-label" [ngStyle]="{'color': item.label.color}">{{item.label.text}}</span>
					<p class="slide-desc mt-3" [ngStyle]="{'color': item.desc.color}">{{item.desc.text}}</p>
				</div>
			</ng-template>
		</ngb-carousel>
	</div>
	<div class="links-wrapper">
		<ul class="links">
			<li>
				<div>
					<img src="/src/images/iconagent.png" class="img-listing" width="49" height="50" />
					<a href="{{policyHolderUrl}}" target="_blank">Policyholder Sign In</a>
				</div>
			</li>
			<li>
				<div>
					<img src="/src/images/iconagent.png" class="img-listing" width="49" height="50" />
					<a href="{{agentUrl}}" target="_blank">Agent Sign In</a>
				</div>
			</li>
			<li>
				<div>
					<img src="/src/images/iconclaimstatushover.png" class="img-listing" width="49"
						height="50" />
					<a href="{{policyHolderUrl}}" target="_blank">Report A Claim</a>
				</div>
			</li>
			<li>
				<div>
					<img src="/src/images/iconmakepaymenthover.png" class="img-listing" width="49"
						height="50" />
					<a [routerLink]="'/quick-pay'">Make A Payment</a>
				</div>
			</li>
		</ul>
	</div>
</div>
