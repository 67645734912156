export const canadaZipPattern = "[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[\\\s][0-9]{1}[a-zA-Z]{1}[0-9]{1}";
export const usZipCodeFiveOrNineDigits = /^\d{5}(?:[-]\d{4})?$/;
export const otherZipPattern = "\\\d{5}";
export const emailPattern: string = "\\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,255}\\s*";

export const countries: Array<{ id: number; name: string; abbreviation: string }> = [
	{ id: 1, name: "United States Of America", abbreviation: "US" },
	{ id: 2, name: "Canada", abbreviation: "CA" },
	{ id: 3, name: "United Kingdom", abbreviation: "GB" },
	{ id: 5, name: "Afghanistan", abbreviation: "AF" },
	{ id: 6, name: "Albania", abbreviation: "AL" },
	{ id: 7, name: "Algeria", abbreviation: "DZ" },
	{ id: 8, name: "Andorra", abbreviation: "AD" },
	{ id: 9, name: "Angola", abbreviation: "AO" },
	{ id: 10, name: "Anguilla", abbreviation: "AI" },
	{ id: 11, name: "Antigua and Barbuda", abbreviation: "AG" },
	{ id: 12, name: "Argentina", abbreviation: "AR" },
	{ id: 13, name: "Armenia", abbreviation: "AM" },
	{ id: 14, name: "Aruba", abbreviation: "AW" },
	{ id: 4, name: "Australia", abbreviation: "AU" },
	{ id: 15, name: "Austria", abbreviation: "AT" },
	{ id: 16, name: "Azerbaijan", abbreviation: "AZ" },
	{ id: 17, name: "Bahamas", abbreviation: "BS" },
	{ id: 18, name: "Bahrain", abbreviation: "BH" },
	{ id: 19, name: "Bangladesh", abbreviation: "BD" },
	{ id: 20, name: "Barbados", abbreviation: "BB" },
	{ id: 21, name: "Belarus", abbreviation: "BY" },
	{ id: 22, name: "Belgium", abbreviation: "BE" },
	{ id: 23, name: "Belize", abbreviation: "BZ" },
	{ id: 24, name: "Benin", abbreviation: "BJ" },
	{ id: 25, name: "Bermuda", abbreviation: "BM" },
	{ id: 26, name: "Bhutan", abbreviation: "BT" },
	{ id: 27, name: "Bolivia", abbreviation: "BO" },
	{ id: 28, name: "Bosnia and Herzegovina", abbreviation: "BA" },
	{ id: 29, name: "Botswana", abbreviation: "BW" },
	{ id: 30, name: "Brazil", abbreviation: "BR" },
	{ id: 31, name: "British Virgin Islands", abbreviation: "VG" },
	{ id: 33, name: "Brunei", abbreviation: "BN" },
	{ id: 34, name: "Bulgaria", abbreviation: "BG" },
	{ id: 35, name: "Burkina Faso", abbreviation: "BF" },
	{ id: 36, name: "Burundi", abbreviation: "BI" },
	{ id: 37, name: "Cambodia", abbreviation: "KH" },
	{ id: 38, name: "Cameroon", abbreviation: "CM" },
	{ id: 39, name: "Cape Verde", abbreviation: "CV" },
	{ id: 40, name: "Cayman Islands", abbreviation: "KY" },
	{ id: 41, name: "Central African Republic", abbreviation: "CF" },
	{ id: 42, name: "Chad", abbreviation: "TD" },
	{ id: 43, name: "Chile", abbreviation: "CL" },
	{ id: 44, name: "China", abbreviation: "CN" },
	{ id: 45, name: "Colombia", abbreviation: "CO" },
	{ id: 46, name: "Comoros", abbreviation: "KM" },
	{ id: 48, name: "Congo", abbreviation: "CG" },
	{ id: 49, name: "Cook Islands", abbreviation: "CK" },
	{ id: 50, name: "Costa Rica", abbreviation: "CR" },
	{ id: 51, name: "Côte D'Ivoire", abbreviation: "CI" },
	{ id: 52, name: "Croatia", abbreviation: "HR" },
	{ id: 53, name: "Cuba", abbreviation: "CU" },
	{ id: 54, name: "Cyprus", abbreviation: "CY" },
	{ id: 55, name: "Czech Republic", abbreviation: "CZ" },
	{ id: 102, name: "Democratic People's Republic of Korea", abbreviation: "KP" },
	{ id: 47, name: "Democratic Republic of the Congo", abbreviation: "CD" },
	{ id: 56, name: "Denmark", abbreviation: "DK" },
	{ id: 57, name: "Djibouti", abbreviation: "DJ" },
	{ id: 58, name: "Dominica", abbreviation: "DM" },
	{ id: 59, name: "Dominican Republic", abbreviation: "DO" },
	{ id: 60, name: "East Timor", abbreviation: "TL" },
	{ id: 61, name: "Ecuador", abbreviation: "EC" },
	{ id: 62, name: "Egypt", abbreviation: "EG" },
	{ id: 63, name: "El Salvador", abbreviation: "SV" },
	{ id: 64, name: "Equatorial Guinea", abbreviation: "GQ" },
	{ id: 65, name: "Eritrea", abbreviation: "ER" },
	{ id: 66, name: "Estonia", abbreviation: "EE" },
	{ id: 67, name: "Ethiopia", abbreviation: "ET" },
	{ id: 68, name: "Falkland Islands", abbreviation: "FK" },
	{ id: 69, name: "Fiji", abbreviation: "FJ" },
	{ id: 70, name: "Finland", abbreviation: "FI" },
	{ id: 71, name: "France", abbreviation: "FR" },
	{ id: 72, name: "French Guiana", abbreviation: "GF" },
	{ id: 73, name: "French Polynesia", abbreviation: "PF" },
	{ id: 75, name: "Gabon", abbreviation: "GA" },
	{ id: 76, name: "Gambia", abbreviation: "GM" },
	{ id: 77, name: "Georgia", abbreviation: "GE" },
	{ id: 78, name: "Germany", abbreviation: "DE" },
	{ id: 79, name: "Ghana", abbreviation: "GH" },
	{ id: 80, name: "Gibraltar", abbreviation: "GI" },
	{ id: 81, name: "Greece", abbreviation: "GR" },
	{ id: 82, name: "Greenland", abbreviation: "GL" },
	{ id: 83, name: "Grenada", abbreviation: "GD" },
	{ id: 84, name: "Guadeloupe", abbreviation: "GP" },
	{ id: 85, name: "Guatemala", abbreviation: "GT" },
	{ id: 86, name: "Guinea", abbreviation: "GN" },
	{ id: 87, name: "Guinea-Bissau", abbreviation: "GW" },
	{ id: 88, name: "Guyana", abbreviation: "GY" },
	{ id: 89, name: "Haiti", abbreviation: "HT" },
	{ id: 90, name: "Honduras", abbreviation: "HN" },
	{ id: 91, name: "Hong Kong", abbreviation: "HK" },
	{ id: 92, name: "Hungary", abbreviation: "HU" },
	{ id: 93, name: "Iceland", abbreviation: "IS" },
	{ id: 94, name: "India", abbreviation: "IN" },
	{ id: 95, name: "Indonesia", abbreviation: "ID" },
	{ id: 209, name: "Iran", abbreviation: "IR" },
	{ id: 210, name: "Iraq", abbreviation: "IQ" },
	{ id: 211, name: "Ireland", abbreviation: "IE" },
	{ id: 212, name: "Israel", abbreviation: "IL" },
	{ id: 213, name: "Italy", abbreviation: "IT" },
	{ id: 96, name: "Jamaica", abbreviation: "JM" },
	{ id: 97, name: "Japan", abbreviation: "JP" },
	{ id: 98, name: "Jordan", abbreviation: "JO" },
	{ id: 99, name: "Kazakhstan", abbreviation: "KZ" },
	{ id: 100, name: "Kenya", abbreviation: "KE" },
	{ id: 101, name: "Kiribati", abbreviation: "KI" },
	{ id: 104, name: "Kuwait", abbreviation: "KW" },
	{ id: 105, name: "Kyrgyzstan", abbreviation: "KG" },
	{ id: 106, name: "Lao People's Democratic Republic", abbreviation: "LA" },
	{ id: 107, name: "Latvia", abbreviation: "LV" },
	{ id: 108, name: "Lebanon", abbreviation: "LB" },
	{ id: 109, name: "Lesotho", abbreviation: "LS" },
	{ id: 110, name: "Liberia", abbreviation: "LR" },
	{ id: 111, name: "Libyan Arab Jamahiriya", abbreviation: "LY" },
	{ id: 112, name: "Liechtenstein", abbreviation: "LI" },
	{ id: 113, name: "Lithuania", abbreviation: "LT" },
	{ id: 114, name: "Luxembourg", abbreviation: "LU" },
	{ id: 115, name: "Macau", abbreviation: "MO" },
	{ id: 116, name: "Macedonia", abbreviation: "MK" },
	{ id: 117, name: "Madagascar", abbreviation: "MG" },
	{ id: 118, name: "Malawi", abbreviation: "MW" },
	{ id: 119, name: "Malaysia", abbreviation: "MY" },
	{ id: 120, name: "Maldives", abbreviation: "MV" },
	{ id: 121, name: "Mali", abbreviation: "ML" },
	{ id: 122, name: "Malta", abbreviation: "MT" },
	{ id: 123, name: "Marshall Islands", abbreviation: "MH" },
	{ id: 124, name: "Martinique", abbreviation: "MQ" },
	{ id: 125, name: "Mauritania", abbreviation: "MR" },
	{ id: 126, name: "Mauritius", abbreviation: "MU" },
	{ id: 127, name: "Mexico", abbreviation: "MX" },
	{ id: 128, name: "Micronesia", abbreviation: "FM" },
	{ id: 129, name: "Moldova", abbreviation: "MD" },
	{ id: 130, name: "Monaco", abbreviation: "MC" },
	{ id: 131, name: "Mongolia", abbreviation: "MN" },
	{ id: 132, name: "Montserrat", abbreviation: "MS" },
	{ id: 133, name: "Morocco", abbreviation: "MA" },
	{ id: 134, name: "Mozambique", abbreviation: "MZ" },
	{ id: 135, name: "Myanmar", abbreviation: "MM" },
	{ id: 137, name: "Nauru", abbreviation: "NR" },
	{ id: 138, name: "Nepal", abbreviation: "NP" },
	{ id: 139, name: "Netherlands", abbreviation: "NL" },
	{ id: 140, name: "Netherlands Antilles", abbreviation: "AN" },
	{ id: 141, name: "New Caledonia", abbreviation: "NC" },
	{ id: 142, name: "New Zealand", abbreviation: "NZ" },
	{ id: 143, name: "Nicaragua", abbreviation: "NI" },
	{ id: 144, name: "Niger", abbreviation: "NE" },
	{ id: 145, name: "Nigeria", abbreviation: "NG" },
	{ id: 146, name: "Norway", abbreviation: "NO" },
	{ id: 147, name: "Oman", abbreviation: "OM" },
	{ id: 148, name: "Pakistan", abbreviation: "PK" },
	{ id: 149, name: "Palau", abbreviation: "PW" },
	{ id: 150, name: "Panama", abbreviation: "PA" },
	{ id: 151, name: "Papua New Guinea", abbreviation: "PG" },
	{ id: 152, name: "Paraguay", abbreviation: "PY" },
	{ id: 153, name: "Peru", abbreviation: "PE" },
	{ id: 154, name: "Philippines", abbreviation: "PH" },
	{ id: 155, name: "Poland", abbreviation: "PL" },
	{ id: 156, name: "Portugal", abbreviation: "PT" },
	{ id: 157, name: "Qatar", abbreviation: "QA" },
	{ id: 103, name: "Republic of Korea", abbreviation: "KR" },
	{ id: 158, name: "Romania", abbreviation: "RO" },
	{ id: 159, name: "Russia", abbreviation: "RU" },
	{ id: 160, name: "Rwanda", abbreviation: "RW" },
	{ id: 161, name: "Saint Kitts and Nevis", abbreviation: "KN" },
	{ id: 162, name: "Saint Lucia", abbreviation: "LC" },
	{ id: 163, name: "Saint Vincent and the Grenadines", abbreviation: "VC" },
	{ id: 164, name: "Samoa", abbreviation: "WS" },
	{ id: 165, name: "San Marino", abbreviation: "SM" },
	{ id: 166, name: "Sao Tome and Principe", abbreviation: "ST" },
	{ id: 167, name: "Saudi Arabia", abbreviation: "SA" },
	{ id: 168, name: "Senegal", abbreviation: "SN" },
	{ id: 169, name: "Serbia and Montenegro", abbreviation: "CS" },
	{ id: 170, name: "Seychelles", abbreviation: "SC" },
	{ id: 171, name: "Sierra Leone", abbreviation: "SL" },
	{ id: 172, name: "Singapore", abbreviation: "SG" },
	{ id: 173, name: "Slovakia", abbreviation: "SK" },
	{ id: 174, name: "Slovenia", abbreviation: "SI" },
	{ id: 175, name: "Solomon Islands", abbreviation: "SB" },
	{ id: 176, name: "Somalia", abbreviation: "SO" },
	{ id: 177, name: "South Africa", abbreviation: "ZA" },
	{ id: 178, name: "Spain", abbreviation: "ES" },
	{ id: 179, name: "Sri Lanka", abbreviation: "LK" },
	{ id: 180, name: "Sudan", abbreviation: "SD" },
	{ id: 181, name: "Suriname", abbreviation: "SR" },
	{ id: 182, name: "Swaziland", abbreviation: "SZ" },
	{ id: 183, name: "Sweden", abbreviation: "SE" },
	{ id: 184, name: "Switzerland", abbreviation: "CH" },
	{ id: 185, name: "Syria", abbreviation: "SY" },
	{ id: 186, name: "Taiwan", abbreviation: "TW" },
	{ id: 187, name: "Tajikistan", abbreviation: "TJ" },
	{ id: 188, name: "Tanzania", abbreviation: "TZ" },
	{ id: 189, name: "Thailand", abbreviation: "TH" },
	{ id: 190, name: "Togo", abbreviation: "TG" },
	{ id: 191, name: "Tonga", abbreviation: "TO" },
	{ id: 192, name: "Trinidad and Tobago", abbreviation: "TT" },
	{ id: 193, name: "Tunisia", abbreviation: "TN" },
	{ id: 194, name: "Turkey", abbreviation: "TR" },
	{ id: 195, name: "Turkmenistan", abbreviation: "TM" },
	{ id: 196, name: "Turks and Caicos Islands", abbreviation: "TC" },
	{ id: 197, name: "Tuvalu", abbreviation: "TV" },
	{ id: 198, name: "Uganda", abbreviation: "UG" },
	{ id: 199, name: "Ukraine", abbreviation: "UA" },
	{ id: 200, name: "United Arab Emirates", abbreviation: "AE" },
	{ id: 201, name: "Uruguay", abbreviation: "UY" },
	{ id: 202, name: "Uzbekistan", abbreviation: "UZ" },
	{ id: 203, name: "Vanuatu", abbreviation: "VU" },
	{ id: 204, name: "Venezuela", abbreviation: "VE" },
	{ id: 205, name: "Vietnam", abbreviation: "VN" },
	{ id: 206, name: "Yemen", abbreviation: "YE" },
	{ id: 207, name: "Zambia", abbreviation: "ZM" },
	{ id: 208, name: "Zimbabwe", abbreviation: "ZW" }
];

export const states: Array<{ id: number; name: string; abbreviation: string }> = [
	{ id: 1, abbreviation: "AK", name: "Alaska" },
	{ id: 2, abbreviation: "AL", name: "Alabama" },
	{ id: 3, abbreviation: "AR", name: "Arkansas" },
	{ id: 4, abbreviation: "AZ", name: "Arizona" },
	{ id: 5, abbreviation: "CA", name: "California" },
	{ id: 6, abbreviation: "CO", name: "Colorado" },
	{ id: 7, abbreviation: "CT", name: "Connecticut" },
	{ id: 8, abbreviation: "DC", name: "District of Columbia" },
	{ id: 9, abbreviation: "DE", name: "Delaware" },
	{ id: 10, abbreviation: "FL", name: "Florida" },
	{ id: 11, abbreviation: "GA", name: "Georgia" },
	{ id: 12, abbreviation: "HI", name: "Hawaii" },
	{ id: 13, abbreviation: "IA", name: "Iowa" },
	{ id: 14, abbreviation: "ID", name: "Idaho" },
	{ id: 15, abbreviation: "IL", name: "Illinois" },
	{ id: 16, abbreviation: "IN", name: "Indiana" },
	{ id: 17, abbreviation: "KS", name: "Kansas" },
	{ id: 18, abbreviation: "KY", name: "Kentucky" },
	{ id: 19, abbreviation: "LA", name: "Louisiana" },
	{ id: 20, abbreviation: "MA", name: "Massachusetts" },
	{ id: 21, abbreviation: "MD", name: "Maryland" },
	{ id: 22, abbreviation: "ME", name: "Maine" },
	{ id: 23, abbreviation: "MI", name: "Michigan" },
	{ id: 24, abbreviation: "MN", name: "Minnesota" },
	{ id: 25, abbreviation: "MO", name: "Missouri" },
	{ id: 26, abbreviation: "MS", name: "Mississippi" },
	{ id: 27, abbreviation: "MT", name: "Montana" },
	{ id: 28, abbreviation: "NC", name: "North Carolina" },
	{ id: 29, abbreviation: "ND", name: "North Dakota" },
	{ id: 30, abbreviation: "NE", name: "Nebraska" },
	{ id: 31, abbreviation: "NH", name: "New Hampshire" },
	{ id: 32, abbreviation: "NJ", name: "New Jersey" },
	{ id: 33, abbreviation: "NM", name: "New Mexico" },
	{ id: 34, abbreviation: "NV", name: "Nevada" },
	{ id: 35, abbreviation: "NY", name: "New York" },
	{ id: 36, abbreviation: "OH", name: "Ohio" },
	{ id: 37, abbreviation: "OK", name: "Oklahoma" },
	{ id: 38, abbreviation: "OR", name: "Oregon" },
	{ id: 39, abbreviation: "PA", name: "Pennsylvania" },
	{ id: 40, abbreviation: "RI", name: "Rhode Island" },
	{ id: 41, abbreviation: "SC", name: "South Carolina" },
	{ id: 42, abbreviation: "SD", name: "South Dakota" },
	{ id: 43, abbreviation: "TN", name: "Tennessee" },
	{ id: 44, abbreviation: "TX", name: "Texas" },
	{ id: 45, abbreviation: "UT", name: "Utah" },
	{ id: 46, abbreviation: "VA", name: "Virginia" },
	{ id: 47, abbreviation: "VT", name: "Vermont" },
	{ id: 48, abbreviation: "WA", name: "Washington" },
	{ id: 49, abbreviation: "WI", name: "Wisconsin" },
	{ id: 50, abbreviation: "WV", name: "West Virginia" },
	{ id: 51, abbreviation: "WY", name: "Wyoming" }
	];

export const provinces: Array<{ id: number; name: string; abbreviation: string }> = [
	{ id: 52, abbreviation: "AB", name: "Alberta" },
	{ id: 53, abbreviation: "BC", name: "British Columbia" },
	{ id: 54, abbreviation: "NB", name: "New Brunswick" },
	{ id: 55, abbreviation: "NL", name: "Newfoundland and Labrador" },
	{ id: 56, abbreviation: "NT", name: "Northwest Territories" },
	{ id: 57, abbreviation: "NS", name: "Nova Scotia" },
	{ id: 58, abbreviation: "ON", name: "Ontario" },
	{ id: 59, abbreviation: "PE", name: "Prince Edward Island" },
	{ id: 60, abbreviation: "QC", name: "Quebec" },
	{ id: 61, abbreviation: "SK", name: "Saskatchewan" },
	{ id: 62, abbreviation: "YT", name: "Yukon" },
	{ id: 67, abbreviation: "MB", name: "Manitoba" },
	{ id: 68, abbreviation: "NN", name: "Nunavut" }
];

export const months = [
	{
		value: "01",
		label: "01 - January"
	},
	{
		value: "02",
		label: "02 - February"
	},
	{
		value: "03",
		label: "03 - March"
	},
	{
		value: "04",
		label: "04 - April"
	},
	{
		value: "05",
		label: "05 - May"
	},
	{
		value: "06",
		label: "06 - June"
	},
	{
		value: "07",
		label: "07 - July"
	},
	{
		value: "08",
		label: "08 - August"
	},
	{
		value: "09",
		label: "09 - September"
	},
	{
		value: "10",
		label: "10 - October"
	},
	{
		value: "11",
		label: "11 - November"
	},
	{
		value: "12",
		label: "12 - December"
	},
];