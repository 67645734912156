import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './module/home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NgbCarouselModule, NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { QuickPayComponent } from './module/quick-pay/quick-pay.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { StepsModule } from 'primeng/steps';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { HttpService } from './core/services/http/http.service';
import { ApiService } from './core/services/api/api.service';
import { SharedDataService } from './core/services/shared/shared-data.service';
import { TextBoxComponent } from './shared/text-box/text-box.component';
import { CommonModule } from '@angular/common';
import { SelectBoxComponent } from './shared/select-box/select-box.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null as any;
import { ScrollToErrorDirective } from './core/directives/scroll-to-error.directive';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { PaymentAutoSubmitComponent } from './shared/payment-auto-submit/payment-auto-submit.component';
import { ContactComponent } from './module/contact/contact.component';
import { CountryNamePipe } from './core/pipes/country-name.pipe';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    QuickPayComponent,
    TextBoxComponent,
    SelectBoxComponent,
    ScrollToErrorDirective,
    PaymentAutoSubmitComponent,
    ContactComponent,
    CountryNamePipe,
  ],
  imports: [
	CommonModule,
	BrowserModule,
	BrowserAnimationsModule,
	HttpClientModule,
	FormsModule,
	ReactiveFormsModule,
	AppRoutingModule,
	NgxMaskModule.forRoot(),
	NgbCarouselModule,
	NgbPopoverModule,
	BreadcrumbModule,
	StepsModule,
	InputTextModule,
	ButtonModule,
	RippleModule,
	DropdownModule,
	InputMaskModule,
	ToastModule,
	NgbNavModule,
	CheckboxModule,
	DialogModule
  ],
  providers: [
	  HttpService,
	  ApiService,
	  SharedDataService,
	  MessageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
