import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-select-box',
	templateUrl: './select-box.component.html',
	styleUrls: ['./select-box.component.less'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectBoxComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.None
})
export class SelectBoxComponent implements ControlValueAccessor
{
	@Input() form: any;
	@Input() options: any;
	@Input() filter: boolean = false;
	@Input() filterBy: string = ""
	@Input() floatLabel: boolean = true;
	@Input() labelText: string = "";
	@Input() optionLabel: string = "";
	@Input() optionValue: string = "";
	@Input() id: string = "";
	@Input() placeholder: string = "Select";
	@Input() errorMsg: string = "";
	@Output() valueChanged = new EventEmitter();
	@ViewChild('selectBox', { static: true, read: ElementRef }) inputElementRef!: ElementRef;
	public panelClosed: boolean = false;

	constructor(private _renderer: Renderer2) { }

	private propagateChange = (_: any) => { };
	public isTouchedCallback = () => { };

	public registerOnChange(fn: any)
	{
		this.propagateChange = fn;
	}

	public registerOnTouched(fn: any)
	{
		this.isTouchedCallback = fn;
	}

	public writeValue(value: any)
	{
		this._renderer.setProperty(this.inputElementRef.nativeElement, 'value', value);
	}

	public isTouched()
	{
		this.isTouchedCallback();
	}

	public valueChange(event: any)
	{
		setTimeout(() => {
			this.valueChanged.emit(event);
		});
	}

}
