<div class="site-head">
	<div class="top-bar">
		<span><strong>Have Questions? </strong><a class="contact-us-link" href="https://www.floridafamily.com/contact-us">Contact us online</a><span> or call 888-850-4663</span></span>
	</div>
	<div class="container-fluid">
		<div class="row">
			<div class="col-5">
				<div class="logo">
					<a href="https://www.floridafamily.com/">
						<img src="/src/images/FFI_logo_horizontal_NOtagline_RGB.jpg" alt="Florida Family Insurance" height="70" />
					</a>
				</div>
			</div>
			<div class="col-7">
				<div class="top-links mt-4">
					<a href="{{agentUrl}}" target="_blank">Agent Sign In</a>
					<a href="{{policyHolderUrl}}" target="_blank">Policyholder Sign In</a>
					<a href="https://www.floridafamily.com/contact-us">Contact Us</a>
				</div>
			</div>
		</div>
	</div>
	<div class="primary-nav-wrap">
		<ul class="primary-nav cf"></ul>
	</div>
</div>
