import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-text-box',
	templateUrl: './text-box.component.html',
	styleUrls: ['./text-box.component.less'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TextBoxComponent),
			multi: true
		}
	]
})
export class TextBoxComponent implements ControlValueAccessor
{
	@Input() form: any;
	@Input() floatLabel: boolean = true;
	@Input() labelText: string = "";
	@Input() id: string = "";
	@Input() placeholder: string = "";
	@Input() maskPattern: string = "";
	@Input() maskValidation: boolean = true;
	@Input() dropSpecialCharacters: boolean = true;
	@Input() maxLength: number = 0;
	@Input() pattern: string = "";
	@Input() errorMsg: string = "";
	@Output() inputEvent = new EventEmitter();
	@ViewChild('textInput', { static: true, read: ElementRef }) inputElementRef!: ElementRef;

	constructor(private _renderer: Renderer2) { }

	private propagateChange = (_: any) => { };
	public isTouchedCallback = () => { };
	
	public registerOnChange(fn: any)
    {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any)
    {
        this.isTouchedCallback = fn;
    }

    public writeValue(value: any)
    {
		this._renderer.setProperty(this.inputElementRef.nativeElement, 'value', value);
    }

    public isTouched()
    {
        this.isTouchedCallback();
	}

    public onBlur()
    {
		this.isTouched();
	}

	public inputChanged()
	{
		this.inputEvent.emit();
	}

}
