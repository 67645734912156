export class AppUrls {
	public static apiUrls: any;
	public static baseApi: string;
	public static basePolicyApi: string;
	public static setBasePathApi(baseApi: string, basePolicyApi: string) {
		AppUrls.baseApi = baseApi;
		AppUrls.basePolicyApi = basePolicyApi;
		AppUrls.apiUrls = {
			get_policy: `${basePolicyApi}/api/policy/getpolicy`,
			get_payment_fields: `${basePolicyApi}/api/payment/getpaymentfields`,
			get_signature: `${basePolicyApi}/api/payment/getsignature`,
            log_event: `${basePolicyApi}/api/eventlogs/post`,
            send_email: `${basePolicyApi}/api/payment/sendemail`,
		}
	}
}