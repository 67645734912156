import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import postscribe from 'postscribe';
import { MenuItem } from 'primeng/api';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.less']
})
export class ContactComponent implements OnInit
{
	public breadcrumbItems: MenuItem[] = [
		{ label: 'Contact Us' },
	];
	public breadcrumbHome: MenuItem = { icon: 'pi pi-home', routerLink: '/' };
	public isLoading: boolean = true;

	constructor(private cdref: ChangeDetectorRef) { }

	ngOnInit(): void
	{
		postscribe('#contact_us_container', "<script src='https://floridafamilyinsurance-sskpp.formstack.com/forms/js.php/contact_us'></script>", {
			done: () => {
			  this.isLoading = false;
			  this.cdref.detectChanges();
			}
		});
	}

}
