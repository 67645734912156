<div>
	<label class="non-float-label" *ngIf="!floatLabel" for="{{id}}">{{labelText}}</label>
	<div>
		<span [ngClass]="{'p-float-label': floatLabel}">
			<input type="text" #textInput pInputText id="{{id}}" [formControl]="form" (input)="inputChanged()"
				class="form-control" placeholder="{{placeholder}}" maxlength="{{maxLength ? maxLength : null}}"
				pattern="{{pattern}}" autocomplete="off" (blur)="onBlur()" [mask]="maskPattern" [validation]="maskValidation" [dropSpecialCharacters]="dropSpecialCharacters"
				[ngClass]="{'p-error is-invalid border-invalid': form?.errors && form?.touched, 'is-valid border-valid': form?.value && form?.valid && form?.touched}">
			<label *ngIf="floatLabel" for="{{id}}">{{labelText}}</label>
		</span>
	</div>
	<div *ngIf="form?.errors && form?.touched" class="p-error">
		<span>{{errorMsg}}</span>
	</div>
</div>