import { Pipe, PipeTransform } from '@angular/core';
import { countries } from '../../config/constants';

@Pipe({
    name: 'countryName'
})
export class CountryNamePipe implements PipeTransform
{
    transform(value: string): string
    {
        return countries.filter((country) => country.abbreviation === value)[0]?.name;
    }
}
