import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit
{
	@ViewChild('carousel', {static : true}) carousel: NgbCarousel | undefined;
	public policyHolderUrl = environment.policyHolderUrl;
	public agentUrl = environment.agentUrl;
    public carouselImages = [
		{ img: "heroimage-AMBestDemotech.jpg", label: { text: "Peace of mind just got a little more peaceful.", color: "#ffffff" }, desc: { text: "We are rated A' Prime by Demotech and B++ by AM Best, making us one of the most financially secure insurance companies in the state.", color: "#ffffff" } },
        { img: "heroimage2.jpg", label: { text: "Dedicated to Protecting Your Home.", color: "#1f497d" }, desc: { text: "See how our standard of service will be there when you need it the most.", color: "#333" } }
    ]; // make dynamic at some point

	constructor() { }

	ngOnInit(): void
	{
		// Home page created by marketing using hubspot, so redirect unless in local
		if (window.location.hostname.indexOf("local") == -1)
		{
			window.location.href = "https://www.floridafamily.com";
		}
	}

	public onCarouselSlide(slideEvent: NgbSlideEvent)
	{
		if (slideEvent.source === NgbSlideEventSource.INDICATOR)
		{
			// reset timer by pausing then starting upon clicking indicator
			this.carousel?.pause();
			this.carousel?.cycle();
		}
	}

}
