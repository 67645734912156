import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

	constructor(private http: HttpClient)
	{ }
	
	public getData(url: string, queryParams = {}, headers = {}, options?: any) {
		const httpParams = new HttpParams({fromObject: queryParams});
		const httpHeaders = new HttpHeaders(this.getHeaders(headers));
		const headerOptions: any = this.getOptions({params: httpParams, headers: httpHeaders }, options);
		return this.http.get(url, headerOptions);
	}

	public postData(url: string, requestBody: {}, headers = {}, options?: any) {
		const httpHeaders = new HttpHeaders(this.getHeaders(headers));
		const headerOptions: any = this.getOptions({headers: httpHeaders }, options);
		return this.http.post(url, requestBody, headerOptions);
	}

	private getHeaders(headers: {} | any) {
		return Object.assign(headers || {}, {
			"Content-Type" : "application/json"
		});
	}

	private getOptions(defaultOptions: {} | any, options: {} | any) {
		return Object.assign(defaultOptions || {}, options || {});
	}
}
