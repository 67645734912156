import { Directive, HostListener } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Directive({
	selector: '[scrollToError]'
})
export class ScrollToErrorDirective
{
	constructor(private controlContainer: ControlContainer) { }

	@HostListener('submit')
	onFormSubmit()
	{
		this.controlContainer.control?.markAllAsTouched();
		this.markFormGroupDirty(this.controlContainer.control as FormGroup); // select needs dirty to show errors
		
		// timeout for updateOn=submit so does not scroll before validated
		setTimeout(() =>
		{
			if (!this.controlContainer.control?.valid) // check if any errors in the formGroup
			{
				const elementsWithErrors: NodeList = document.querySelectorAll('input.ng-invalid, p-dropdown.ng-invalid, select.ng-invalid, textarea.ng-invalid');
				if (elementsWithErrors.length > 0)
				{
					const firstElementWithError: HTMLElement = elementsWithErrors[0] as HTMLElement;
					if ((window as any).document["documentMode"]) // IE
					{
						firstElementWithError.focus();
					}
					else
					{
						firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
						firstElementWithError.focus({ preventScroll: true });
					}
				}
			}
		})
	}

	private markFormGroupDirty(formGroup: FormGroup)
	{
		let values = Object.keys(formGroup.controls).map(e => formGroup.controls[e]);
		values.forEach((control: any) =>
		{
			control.markAsDirty();
			if (control["controls"])
			{
				this.markFormGroupDirty(<FormGroup>control);
			}
		});
	}
}
