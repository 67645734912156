import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AppUrls } from './config/api-urls';
import { Utilities } from './shared/utilities/utilities';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent
{
	constructor(private titleService: Title, private router: Router, private primengConfig: PrimeNGConfig)
	{
		// Setting urls based on bypass url (testing with maintenance page up in production)
		if (window.location.href.includes("-bypass"))
		{
			AppUrls.setBasePathApi(environment.baseUrl, environment.bypassPolicyUrl);
		}
		else
		{
			AppUrls.setBasePathApi(environment.baseUrl, environment.basePolicyUrl);
		}

		this.primengConfig.ripple = true;

		this.router.events.subscribe((event: any) =>
		{
			if (event instanceof NavigationEnd)
			{
				const title = Utilities.getTitle(this.router.routerState, this.router.routerState.root).join('-');
				this.titleService.setTitle(title);
			}
		});
	}
}
