import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppUrls } from 'src/app/config/api-urls';
import { HttpService } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService
{

	constructor(
		private httpService: HttpService
	) { }

	public getPaymentFields(): Observable<any>
	{
		return this.httpService.getData(AppUrls.apiUrls.get_payment_fields);
	}

	public getPolicyData(policyNumber: any, lastName: any, zipCode: any): Observable<any>
	{
		return this.httpService.getData(AppUrls.apiUrls.get_policy, { policyNumber: policyNumber, lastName: lastName, zipCode: zipCode });
	}

	public getSignature(data: any): Observable<any>
	{
		return this.httpService.getData(AppUrls.apiUrls.get_signature, { data: data });
    }

    public logEvent(data: any): Observable<any>
    {
        return this.httpService.postData(AppUrls.apiUrls.log_event, data);
    }

    public sendEmail(data: any, email: string, subject: string): Observable<any>
    {
        return this.httpService.postData(AppUrls.apiUrls.send_email, data, {}, { params: { email: email, subject: subject } });
    }

}