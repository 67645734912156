import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit
{
	public policyHolderUrl = environment.policyHolderUrl;
	public agentUrl = environment.agentUrl;

	constructor() { }

	ngOnInit(): void
	{
	}

}
