<div class="mb-2 no-print">
	<p-breadcrumb [model]="breadcrumbItems" [home]="breadcrumbHome" [styleClass]="'background-inherit'"></p-breadcrumb>
</div>
<div class="page-title mb-4">
	<span>Quick Pay</span>
</div>
<div class="mb-3 no-print">
	<p-steps [model]="stepItems" [(activeIndex)]="activeIndex"></p-steps>
</div>
<div class="step-content mb-5">
	<div id="tabPayment" class="tab bg-light">
		<div class="row">
			<div class="col-9 h4">
				<i *ngIf="activeIndex === 3 && confirmationPageError" class="fas fa-times-circle text-danger mr-2"></i>
				<i *ngIf="activeIndex === 3 && !confirmationPageError" class="fas fa-check-circle text-success mr-2"></i>
				<span>{{paymentTitle}}</span>
			</div>
			<div *ngIf="activeIndex === 3 && !confirmationPageError" class="col-3">
                <button class="p-button-info no-print" pButton pRipple type="button" label="Email" (click)="displaySendEmailModal=true">
                    <i class="fas fa-envelope mr-2"></i>
                </button>
                <button class="p-button-info no-print" pButton pRipple type="button" label="Print" (click)="printDoc()">
                    <i class="fas fa-print mr-2"></i>
                </button>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<p>{{paymentDesc}}</p>
			</div>
		</div>

		<form *ngIf="activeIndex === 0" [formGroup]="findPolicyForm" id="findBillForm" class="mt-5" (ngSubmit)="submitBtn.focus(); findMyBill()"
			scrollToError>
			<div class="form-group row">
				<div class="col-6 mb-4 pb-1">
					<app-text-box [form]="findPolicyForm.controls.lastName" formControlName="lastName"
						[labelText]="'Last Name of Insured'" [id]="'lastName'" [errorMsg]="'Last Name is required.'">
					</app-text-box>
				</div>
			</div>
			<div class="form-group row">
				<div class="col-6 mb-4 pb-1">
					<app-text-box [form]="findPolicyForm.controls.policyNumber" formControlName="policyNumber"
						[labelText]="'Policy Number'" [id]="'policyNumber'" [errorMsg]="'Policy Number is required.'">
					</app-text-box>
				</div>
			</div>
			<div class="form-group row">
				<div class="col-6 mb-5 pb-1">
					<app-text-box [form]="findPolicyForm.controls.zipCode" formControlName="zipCode"
						[maskPattern]="'00000'" [maxLength]="5" [labelText]="'Property Zip'" [id]="'zipCode'"
						[errorMsg]="'Please provide a valid zip code.'">
					</app-text-box>
				</div>
			</div>

			<button #submitBtn pButton pRipple type="submit" label="Find My Bill">
				<i class="fas fa-spinner fa-spin mr-2" *ngIf="isLoading"></i>
			</button>
		</form>

		<div *ngIf="activeIndex !== 0" class="row pt-3 pb-3 account-information clearfix bg-white">
			<div class="col-6">
				<div class="row">
					<div class="col-6 float-left">
						<label>Bill Amount: </label>
					</div>
					<div class="col-6 float-left">
						<span class="policy-bill-amount">
							{{policyData?.NextAmtDue | currency:'USD':'symbol-narrow':'1.0-0'}}
						</span>
					</div>
				</div>
				<div class="row">
					<div class="col-6 float-left">
						<label>Payment Due:</label>
					</div>
					<div class="col-6 float-left">
						<span class="policy-bill-date">
							{{policyData?.NextDueDate | date:'longDate'}}
						</span>
					</div>
				</div>
				<div class="row mt-4">
					<div class="col-12">
						<em><small>Payment amount must be the amount currently due. For more payment options, please
								call
								our customer service department at (888) 850-4663.</small></em>
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="row">
					<div class="col-6 float-left">
						<label>Name of Insured:</label>
					</div>
					<div class="col-6 float-left">
						<span class="policy-account-name">{{policyData?.PolicyHolderName}}</span>
					</div>
				</div>
				<div class="row">
					<div class="col-6 float-left">
						<label>Policy Number:</label>
					</div>
					<div class="col-6 float-left">
						<span class="policy-account-number">{{policyData?.PolicyNumber}}</span>
					</div>
				</div>
				<div class="row">
					<div class="col-6 float-left">
						<label>Property Address:</label>
					</div>
					<div class="col-6 float-left">
						<div class="address-label policy-account-address">
							<div class="address">{{policyData?.PolicyPropertyAddress?.Address1}}</div>
							<div>
								<span class="city">{{policyData?.PolicyPropertyAddress?.City + ', '}}</span>
								<span class="state">{{policyData?.PolicyPropertyAddress?.State + ' '}}</span>
								<span class="postal">{{policyData?.PolicyPropertyAddress?.PostalCode}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="policyData?.NextAmtDue <= 0 && activeIndex === 1" class="row justify-content-center">
			<div class="col-12">
				<div class="alert alert-info text-center h5">
					No payment is currently due.
				</div>
			</div>
		</div>
		<div *ngIf="policyData?.NextAmtDue > 0 || activeIndex === 3" class="row justify-content-center">
			<div class="col-9">
				<div *ngIf="activeIndex === 1" class="payment-form mt-5">
					<form [formGroup]="paymentInfoForm" id="paymentInfo" (ngSubmit)="submitBtn.focus(); goToVerify()"
						scrollToError>
						<div class="card">
							<div class="card-header">
								<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="resetForm($event)"
									[destroyOnHide]="false" class="nav-pills justify-content-center">
									<li ngbNavItem="card">
										<a ngbNavLink><i class="fas fa-credit-card mr-2"></i>Pay With Credit Card</a>
										<ng-template ngbNavContent>
											<div class="row">
												<div class="col-12 credit-card-payment">
													<div class="payment-method-body p-3" formGroupName="cardPayment">
														<div class="form-group row pb-3">
															<div class="col-12">
																<div class="p-field-checkbox">
																	<p-checkbox [formControl]="checkboxFormControl" (onChange)="fillBillingAddress($event)"
																		[binary]="true" inputId="sameAsPropAdr"></p-checkbox>
																	<label class="sameAsProp" for="sameAsPropAdr">Same as Property
																		Address</label>
																</div>
															</div>
														</div>
														<div class="form-group row">
															<div class="col-12 mb-3">
																<app-text-box
																	[form]="cardPaymentForm.controls.firstName" (inputEvent)="clearSameAsBilling()"
																	formControlName="firstName"
																	[labelText]="'First Name'" [id]="'firstName'"
																	[errorMsg]="'First Name is required.'">
																</app-text-box>
															</div>
														</div>
														<div class="form-group row">
															<div class="col-12 mb-3">
																<app-text-box [form]="cardPaymentForm.controls.lastName" (inputEvent)="clearSameAsBilling()"
																	formControlName="lastName" [labelText]="'Last Name'"
																	[id]="'lastName'"
																	[errorMsg]="'Last Name is required.'">
																</app-text-box>
															</div>
														</div>
														<div class="form-group row">
															<div class="col-12 mb-3">
																<app-text-box
																	[form]="cardPaymentForm.controls.streetAddress" (inputEvent)="clearSameAsBilling()"
																	formControlName="streetAddress"
																	[labelText]="'Street Address'"
																	[id]="'streetAddress'"
																	[errorMsg]="'Street Address is required.'">
																</app-text-box>
															</div>
														</div>
														<div class="form-group row">
															<div class="col-12 mb-3">
																<app-select-box
																	[form]="cardPaymentForm.controls.country"
																	[options]="countries" [filter]="true"
																	[filterBy]="'name'" [optionLabel]="'name'"
																	(valueChanged)="setCountry($event); clearSameAsBilling()"
																	[optionValue]="'abbreviation'"
																	formControlName="country" [labelText]="'Country'"
																	[id]="'country'"
																	[errorMsg]="'Country is required.'">
																</app-select-box>
															</div>
														</div>
														<div class="form-group row">
															<div class="mb-3"
																 [ngClass]="cardPaymentForm.controls.country.value === 'US' || cardPaymentForm.controls.country.value === 'CA' ? 'col-4' : 'col-6'">
																<app-text-box [form]="cardPaymentForm.controls.city" (inputEvent)="clearSameAsBilling()"
																	formControlName="city" [labelText]="cityLabel"
																	[id]="'city'" [errorMsg]="cityLabel + ' is required.'">
																</app-text-box>
															</div>
															<div *ngIf="cardPaymentForm.controls.country.value === 'US' || cardPaymentForm.controls.country.value === 'CA'"
																class="col-4 mb-3">
																<app-select-box [form]="cardPaymentForm.controls.state"
																	[options]="statesOrProvinces"
																	[optionLabel]="'abbreviation'"
																	[optionValue]="'abbreviation'"
																	formControlName="state" (valueChanged)="clearSameAsBilling()"
																	[labelText]="stateProvinceLabel" [id]="'state'"
																	[errorMsg]="stateProvinceLabel + ' is required.'">
																</app-select-box>
															</div>
															<div *ngIf="cardPaymentForm.controls.country.value === 'US' || cardPaymentForm.controls.country.value === 'CA'"
																 class="col-4 mb-3">
																<app-text-box [form]="cardPaymentForm.controls.zip" (inputEvent)="clearSameAsBilling()"
																	formControlName="zip" [maskPattern]="zipPostalMask"
																	[maskValidation]="false"
																	[labelText]="zipPostalLabel" [id]="'zip'"
																	[maxLength]="zipPostalMaxLength"
																	[dropSpecialCharacters]="false"
																	[errorMsg]="'Please enter a valid Postal Code.'">
																</app-text-box>
															</div>
														</div>
														<div class="form-group row">
															<div class="col-12">
																<app-text-box
																	[form]="cardPaymentForm.controls.cardNumber"
																	[maskPattern]="'0000 0000 0000 0000'"
																	formControlName="cardNumber"
																	[labelText]="'Card Number'" [id]="'cardNumber'"
																	[errorMsg]="cardPaymentForm.controls.cardNumber?.errors?.required 
																		? 'Card Number is required.'
																		: 'Please reenter the 16-digit credit card number, as the number entered was not recognized as a Visa or MasterCard number.'">
																</app-text-box>
															</div>
														</div>
														<ng-template #popTitle>CVV - General Information</ng-template>
														<ng-template #popContent>
															<div>
																<img src='/src/images/CVV_image.png'
																	width="238px" height="150px">
															</div>
															<br>
															<div>
																If you have a question on this section or any others,
																contact us
																by email or call
																our customer service department at (888) 850-4663,
																Monday
																through Friday, from
																8:30am to 5:00pm.
															</div>
														</ng-template>
														<div class="row">
															<div class="col-12">
																<div class="popover-help" [ngbPopover]="popContent"
																	[popoverTitle]="popTitle">
																	<i class="fas fa-question-circle fa-lg"></i>
																</div>
															</div>
														</div>
														<div class="form-group row">
															<div class="col-5">
																<app-select-box
																	[form]="cardPaymentForm.controls.expMonth"
																	[options]="months" formControlName="expMonth"
																	[labelText]="'Month'" [id]="'expMonth'"
																	[errorMsg]="'Month is required.'">
																</app-select-box>
															</div>
															<div class="col-4 ">
																<app-select-box
																	[form]="cardPaymentForm.controls.expYear"
																	[options]="creditCardYears"
																	formControlName="expYear" [labelText]="'Year'"
																	[id]="'expYear'" [errorMsg]="'Year is required.'">
																</app-select-box>
															</div>
															<div class="col-3 pl-1 pr-2">
																<app-text-box [form]="cardPaymentForm.controls.cvv"
																	formControlName="cvv" [maxLength]="3"
																	[maskPattern]="'000'" [labelText]="'CVV'"
																	[id]="'cvv'" [errorMsg]="'CVV is required.'">
																</app-text-box>
															</div>
														</div>
														<div *ngIf="isCCExpireDateInvalid()" class="row">
															<div class="col-12">
																<span class="p-error">Month/Year combination must not be in the past.</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</ng-template>
									</li>
									<li ngbNavItem="echeck">
										<a ngbNavLink>Pay With Check</a>
										<ng-template ngbNavContent>
											<div class="row">
												<div class="col-12 check-payment">
													<div class="payment-method-body p-3" formGroupName="checkPayment">
														<div class="form-group row pt-3">
															<div class="col-12 mb-3">
																<app-text-box
																	[form]="checkPaymentForm.controls.routingNum"
																	[maskPattern]="'000000000'"
																	formControlName="routingNum"
																	[labelText]="'Routing Number'" [id]="'routingNum'"
																	[errorMsg]="'Please enter a valid Routing Number.'">
																</app-text-box>
															</div>
														</div>
														<div class="form-group row">
															<div class="col-12 mb-3">
																<app-text-box
																	[form]="checkPaymentForm.controls.accountNum"
																	[maskPattern]="'00000999999999999'" [maxLength]="17"
																	formControlName="accountNum"
																	[labelText]="'Account Number'" [id]="'accountNum'"
																	[errorMsg]="'Please enter a valid Account Number.'">
																</app-text-box>
															</div>
														</div>
														<div class="row">
															<div class="col-12">
																<img
																	src='/src/images/check_large_w_linesandtext.png'>
															</div>
														</div>
													</div>
												</div>
											</div>
										</ng-template>
									</li>
								</ul>
							</div>
							<div class="card-body card-body-bg">
								<div [ngbNavOutlet]="nav" class="mt-2"></div>
							</div>
							<div class="card-footer">
								<div class="mt-3">
									<div class="row">
										<div class="col-6">
											<button pButton pRipple class="w-100 p-button-outlined p-button-secondary"
												type="button" label="Back" (click)="goBack()"></button>
										</div>
										<div class="col-6">
											<button class="w-100" #submitBtn pButton pRipple type="submit"
												label="Continue to Verification">
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
				
				<div *ngIf="!confirmationPageError && (activeIndex === 2 || activeIndex === 3)" class="card mt-4">
					<div class="card-header">
						<h3>Payment Information</h3>
					</div>
					<!-- Verification Page -->
					<div *ngIf="activeIndex === 2" class="card-body payment-information">
						<div class="row">
							<div class="col-6" *ngIf="activeTab === 'echeck'">
								<div class="check-payment">
									<div class="row">
										<div class="col-6 float-left">
											<label>Account Number: </label>
										</div>
										<div class="col-6 float-left">
											<span class="payment-account-number">{{hiddenAccountNumber}}</span>
										</div>
									</div>
									<div class="row">
										<div class="col-6 float-left">
											<label>Routing Number:</label>
										</div>
										<div class="col-6 float-left">
											<span class="payment-routing-number">{{checkPaymentForm.controls.routingNum.value}}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-6" *ngIf="activeTab === 'card'">
								<div class="credit-payment">
									<div class="row">
										<div class="col-6 float-left">
											<label>Cardholder: </label>
										</div>
										<div class="col-6 float-left">
											<span class="payment-credit-cardholder">{{cardPaymentForm.controls.firstName.value + ' '}}{{cardPaymentForm.controls.lastName.value}}</span>
										</div>
									</div>
									<div class="row pb-2">
										<div class="col-6 float-left">
											<label>Billing Address:</label>
										</div>
										<div class="col-6 float-left">
											<div class="address-label payment-credit-billing-address">
												<div class="address">{{cardPaymentForm.controls.streetAddress.value}}</div>
												<div>
													<span class="city">{{cardPaymentForm.controls.city.value}}</span><span *ngIf="cardPaymentForm.controls.country.value === 'US' || cardPaymentForm.controls.country.value === 'CA'">, </span>
													<span *ngIf="cardPaymentForm.controls.state.value" class="state">{{cardPaymentForm.controls.state.value + ' '}}</span>
													<span *ngIf="cardPaymentForm.controls.zip.value" class="postal">{{cardPaymentForm.controls.zip.value}}</span>
												</div>
												<div *ngIf="cardPaymentForm.controls.country.value !== 'US' && cardPaymentForm.controls.country.value !== 'CA'" class="country">{{cardPaymentForm.controls.country.value | countryName}}</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-6 float-left">
											<label>Card Number: </label>
										</div>
										<div class="col-6 float-left">
											<span class="payment-credit-cardnumber">{{maskedCreditCard}}</span>
										</div>
									</div>
									<div class="row">
										<div class="col-6 float-left">
											<label>Expiration Date:</label>
										</div>
										<div class="col-6 float-left">
											<span class="payment-credit-exp-date">{{cardPaymentForm.controls.expMonth.value}}-{{cardPaymentForm.controls.expYear.value}}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="payment-details">
									<div class="row">
										<div class="col-6 float-left">
											<label>Payment Date:</label>
										</div>
										<div class="col-6 float-left">
											<span class="payment-credit-date">{{todaysDate | date:'MM/dd/yyyy'}}</span>
										</div>
									</div>
									<div class="row">
										<div class="col-6 float-left">
											<label>Payment Amount:</label>
										</div>
										<div class="col-6 float-left">
											<span class="payment-credit-amount">{{policyData?.NextAmtDue | currency:'USD':'symbol-narrow':'1.0-0'}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Confirmation Page -->
					<div *ngIf="activeIndex === 3" class="card-body payment-information">
						<div class="row">
							<div class="col-6" *ngIf="activeTab === 'echeck'">
								<div class="check-payment">
									<div class="row">
										<div class="col-6 float-left">
											<label>Account Number: </label>
										</div>
										<div class="col-6 float-left">
                                            <span class="payment-account-number">{{cyberSourceReturnedData?.eCheckAccountNumber}}</span>
										</div>
									</div>
									<div class="row">
										<div class="col-6 float-left">
											<label>Routing Number:</label>
										</div>
										<div class="col-6 float-left">
                                            <span class="payment-routing-number">{{cyberSourceReturnedData?.eCheckRoutingNumber}}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-6" *ngIf="activeTab === 'card'">
								<div class="credit-payment">
									<div class="row">
										<div class="col-6 float-left">
											<label>Cardholder: </label>
										</div>
										<div class="col-6 float-left">
                                            <span class="payment-credit-cardholder">{{cyberSourceReturnedData?.BillToFirstName + ' ' + cyberSourceReturnedData?.BillToLastName}}</span>
										</div>
									</div>
									<div class="row pb-2">
										<div class="col-6 float-left">
											<label>Billing Address:</label>
										</div>
										<div class="col-6 float-left">
											<div class="address-label payment-credit-billing-address">
                                                <div class="address">{{cyberSourceReturnedData?.BillToAddressLine1}}</div>
												<div>
													<span class="city">{{cyberSourceReturnedData?.BillToAddressCity}}</span><span *ngIf="cyberSourceReturnedData?.BillToAddressCountry === 'US' || cyberSourceReturnedData?.BillToAddressCountry === 'CA'">, </span>
													<span *ngIf="cyberSourceReturnedData?.BillToAddressState" class="state">{{cyberSourceReturnedData?.BillToAddressState + ' '}}</span>
													<span *ngIf="cyberSourceReturnedData?.BillToAddressPostalCode" class="postal">{{cyberSourceReturnedData?.BillToAddressPostalCode}}</span>
												</div>
												<div *ngIf="cyberSourceReturnedData?.BillToAddressCountry !== 'US' && cyberSourceReturnedData?.BillToAddressCountry !== 'CA'" class="country">{{cyberSourceReturnedData?.BillToAddressCountry | countryName}}</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-6 float-left">
											<label>Card Number: </label>
										</div>
										<div class="col-6 float-left">
                                            <span class="payment-credit-cardnumber">{{cyberSourceReturnedData?.CardNumber}}</span>
										</div>
									</div>
									<div class="row">
										<div class="col-6 float-left">
											<label>Expiration Date:</label>
										</div>
										<div class="col-6 float-left">
                                            <span class="payment-credit-exp-date">{{cyberSourceReturnedData?.CardExpirationDate}}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="payment-details">
									<div class="row">
										<div class="col-6 float-left">
											<label>Payment Date:</label>
										</div>
										<div class="col-6 float-left">
											<span class="payment-credit-date">{{todaysDate | date:'MM/dd/yyyy'}}</span>
										</div>
									</div>
									<div class="row">
										<div class="col-6 float-left">
											<label>Payment Amount:</label>
										</div>
										<div class="col-6 float-left">
											<span class="payment-credit-amount">{{policyData?.NextAmtDue | currency:'USD':'symbol-narrow':'1.0-0'}}</span>
										</div>
									</div>
									<div class="row">
										<div class="col-6 float-left">
											<label>Confirmation Code:</label>
										</div>
										<div class="col-6 float-left">
                                            <span class="payment-credit-exp-date">{{activeTab === "echeck" ? cyberSourceReturnedData?.eCheckDebitReferenceNumber : cyberSourceReturnedData?.AuthorizedTransactionReferenceNumber}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="activeIndex === 2" class="card-footer">
						<div class="mt-3">
							<div class="alert alert-info" role="alert">
								By selecting Process Payment, I am authorizing Florida Family Insurance to process
								payment in the amount shown above. I understand that this is a one time authorization for payment.
							</div>
							<div class="row">
								<div class="col-6">
									<button pButton pRipple class="w-100 p-button-outlined p-button-secondary"
										type="button" label="Back" (click)="goBack()"></button>
								</div>
								<div class="col-6">
									<button class="w-100" pButton pRipple type="button" (click)="processPayment()" label="Process Payment">
										<i class="fas fa-lock lock-icon-size" *ngIf="!isLoading"></i>
										<i class="fas fa-spinner fa-spin mr-2" *ngIf="isLoading"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="activeIndex === 3 && confirmationPageError" class="row mt-3">
					<div class="payment-error alert alert-danger">
						<h2></h2>
						<span>We are unable to process your payment with the information you provided. {{confirmationPageErrorMsg}}</span>
						<span>
							For assistance, please call our Customer Service Department at (888) 850-4663. Our Representatives are
							available Monday through Friday from 8:30 AM to 5:00 PM ET.
						</span>
						<div class="row mt-2">
							<div class="col-6">
								<button pButton pRipple class="w-100" type="button" label="Click here to try again"
									(click)="goBackFromConfirmError()"></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<p-dialog header="Email Confirmation" [(visible)]="displaySendEmailModal" [modal]="true" [style]="{width: '50vw'}"
	(onHide)="sendEmailForm.controls.emailAddress.reset()" [baseZIndex]="10000" [draggable]="false"
	[resizable]="false">
	<form [formGroup]="sendEmailForm" (ngSubmit)="sendEmail()" #emailForm="ngForm">
		<div class="mt-3">
			<app-text-box [form]="sendEmailForm.controls.emailAddress" formControlName="emailAddress"
				[labelText]="'Email Address'" [id]="'emailAddress'" [errorMsg]="'Please enter a valid Email Address.'">
			</app-text-box>
		</div>
	</form>
	<ng-template pTemplate="footer">
		<button pButton pRipple type="submit" label="Send Email" (click)="emailForm.onSubmit($event)">
			<i class="fas fa-spinner fa-spin mr-2" *ngIf="sendEmailLoading"></i>
		</button>
	</ng-template>
</p-dialog>
<app-payment-auto-submit *ngIf="paymentReqObj" [formData]="paymentReqObj"></app-payment-auto-submit>

