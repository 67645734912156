import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-payment-auto-submit',
	templateUrl: './payment-auto-submit.component.html',
	styleUrls: ['./payment-auto-submit.component.less']
})
export class PaymentAutoSubmitComponent implements AfterViewInit
{
	@ViewChild("paymentForm") paymentForm!: ElementRef;

	@Input() formData: any;
	public paymentUrl: string = environment.cyberSourceURL;

	constructor()
	{ }

	ngAfterViewInit()
	{
		this.paymentForm.nativeElement.submit();
	}

	// Preserve original property order
	public keepOrigOrder(): number
    {
        return 0;
    }

}
