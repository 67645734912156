import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './module/contact/contact.component';
import { HomeComponent } from './module/home/home.component';
import { QuickPayComponent } from './module/quick-pay/quick-pay.component';

const routes: Routes = [
	{ path: "", component: HomeComponent, data: { title: "Florida Family Insurance" } },
	{ path: "quick-pay", component: QuickPayComponent, data: { title: "Quick Pay" } },
	{ path: "contact-us", component: ContactComponent, data: { title: "Contact Us" } },
	{ path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
