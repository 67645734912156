<div>
	<label class="non-float-label" *ngIf="!floatLabel" for="{{id}}">{{labelText}}</label>
	<div [ngClass]="{'invalid-dropdown' : form?.errors && (form?.dirty || panelClosed), 'valid-dropdown': form?.valid && (form?.dirty || panelClosed)}">
		<span [ngClass]="{'p-float-label': floatLabel}">
			<p-dropdown #selectBox styleClass="prime-dropdown" [options]="options" [formControl]="form" [optionLabel]="optionLabel" [autoDisplayFirst]="!floatLabel"
			[filter]="filter" [filterBy]="filterBy" placeholder="{{placeholder}}" [inputId]="id" [optionValue]="optionValue"
			(onHide)="panelClosed = true" (onChange)="valueChange($event)">
			</p-dropdown>
			<label *ngIf="floatLabel" for="{{id}}">{{labelText}}</label>
		</span>
	</div>
	<div *ngIf="form?.errors && form?.touched && (form?.dirty || panelClosed)" class="p-error">
		<span>{{errorMsg}}</span>
	</div>
</div>