export class Utilities {

	// collect title data properties from all child routes
  public static getTitle(state: any, parent: any): Array<any>
  {
    var data: any = [];

    if (parent && parent.snapshot.data && parent.snapshot.data.title)
    {
			data.push(parent.snapshot.data.title);
		}

    if (state && parent)
    {
			data.push(...this.getTitle(state, state.firstChild(parent)));
    }

		return data;
	}
}
